import Vue from "vue";
import Vuex from "vuex";
import FirebaseModule from './FirebaseModule'
import user from './user'
import point from './point';
import leaderboard from './leaderboard';
import admin from './admin';
import prediction from './prediction';
import shared from './shared'
import report from './report'
import { SEASONS } from "./seasons_17";
// import { FIGHTERS } from "./known_fighter_list";
import { LANG } from '../locale/language'
import { LANG_OLD } from '../const/language';
import { FAQ } from "../const/faq";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      dark: true,
      accType: 0,
      firebaseUserId:
        localStorage.getItem('firebaseId') === null
          ? ''
          : localStorage.getItem('firebaseId'),
      email:
        localStorage.getItem('email') === null
          ? ''
          : localStorage.getItem('email'),
      emailLink:
        localStorage.getItem('emailLink') === null
          ? ''
          : localStorage.getItem('emailLink'),
    },
    reloadCount: 0,
    liveLeaderBoardEventId: '',
    seasons: SEASONS,
    faq: FAQ,
    fighters: [],
    lang: LANG,
    lang_old: LANG_OLD,
    current_language: 'id',
      // localStorage.getItem('current_language') === null
      //   ? 'en'
      //   : localStorage.getItem('current_language'),
    homeBannerMobile01:  '/img/home/home_bg_mobile_03.webp',
    homeBannerMobileLink01: '',
    homeBannerDesktop01: '/img/home/home_bg_new_03.webp',
    homeBannerDesktopLink01: '',
    homeBannerMobile02:  '/img/home/home_bg_mobile_03.webp',
    homeBannerMobileLink02: '',
    homeBannerDesktop02: '/img/home/home_bg_new_03.webp',
    homeBannerDesktopLink02: '',
    homeBannerMobile03:  '/img/home/home_bg_mobile_03.webp',
    homeBannerMobileLink03: '',
    homeBannerDesktop03: '/img/home/home_bg_new_03.webp',
    homeBannerDesktopLink03: '',
    homeTitle: {en: '', id: '', it: ''},
    homeTagline: {en: '', id: '', it: ''},
    blankTitle: false,
    blankTagline: false,
    carouselInterval: 0,
    superLiveYellow: '#f8df8e',
    superLiveRed: '#db0019',
    notificationCount: 0,
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setLiveLeaderbordEventId(state, payload) {
      console.log("Live Leaderboard", payload);
      state.liveLeaderBoardEventId = payload;
    },
    setReloadCounter(state, payload) {
      console.log(payload);
      state.reloadCount = state.reloadCount += 1;
    },
    SetTheme(state, payload) {
      state.user.dark = payload.dark;
      localStorage.setItem('darkTheme', payload.dark);
    },
    SetCustomFighters(state, payload) {
      state.fighters = payload;
    },
    SetUser(state, payload) {
      const keys = ['firebaseId', 'emailLink', 'email', 'isLoggedIn'];
      const values = [
        payload.firebaseUserId,
        payload.emailLink,
        payload.email,
        payload.isLoggedIn,
      ];
      state.user.firebaseUserId = payload.firebaseUserId;
      state.user.email = payload.email;
      state.user.userName = payload.userName;
      state.user.isLoggedIn = payload.isLoggedIn;
      state.user.accType = payload.accType;
      state.user.emailLink = payload.emailLink;
      keys.forEach((key, index) => {
        console.log(index);
        localStorage.setItem(key, values[index]);
      });
    },
    SetDefaultUser(state, payload) {
      const keys = ['firebaseId', 'emailLink', 'email', 'isLoggedIn'];
      state.user.firebaseUserId = payload;
      state.user.email = payload;
      state.user.userName = payload;
      state.user.isLoggedIn = payload;
      state.user.accType = payload;
      state.user.emailLink = payload;
      keys.forEach((key) => {
        localStorage.removeItem(key);
      });
    },
    SetCurrentLanguage(state, payload) {
      console.log('SetCurrentLanguage', payload);
      state.current_language = payload;
      localStorage.setItem('current_language', payload);
    },
    SetState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {},
  modules: {
    fb: FirebaseModule,
    user: user,
    shared: shared,
    point: point,
    admin: admin,
    leaderboard: leaderboard,
    prediction: prediction,
    report: report
  },
});
